import React, { useContext } from "react"
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import fetch from "isomorphic-fetch"
import { AuthContext } from "./AuthProvider"

export const ApolloContext = ({ children }) => {
  const { isAuthenticated, accessToken } = useContext(AuthContext)

  const httpLink = createHttpLink({
    uri: `${process.env.GATSBY_API_GRAPHQL}/omdc/v1/graphql`,
    fetch,
  })

  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: isAuthenticated ? accessToken : "",
        "x-api-key": process.env.GATSBY_API_GRAPHQL_APIKEY,
      },
    }
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
