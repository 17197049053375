import React, { useState, createContext, useEffect, useMemo } from "react"
import { onAuthStateChanged, signOut, onIdTokenChanged } from "firebase/auth"
import { navigate } from "gatsby"

import { auth } from "../firebase"
import { CREATE_USER } from "../queries/users"
import { buildGraphqlClient } from "./fetcher"

const initialValue = {
  user: null,
  isAuthenticated: false,
  isLoading: true,
  scopesGranted: [],
  logout: () => {},
  accessToken: null,
}

export const AuthContext = createContext(initialValue)

export const AuthContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [scopesGranted, setScopesGranted] = useState([])
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, _user => {
      console.log("onIdTokenChanged ", _user)
      if (_user) {
        setAccessToken(_user.accessToken)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async _user => {
      console.log("onAuthStateChanged ")

      if (_user) {
        console.log("authorization ", _user.accessToken)
        const omdcApiFetcher = buildGraphqlClient(_user.accessToken)
        const { createUser: userCreated } = await omdcApiFetcher(CREATE_USER, {
          user: {
            email: _user.email,
            email_verified: _user.emailVerified,
            picture: _user.photoURL,
            uid: _user.uid,
          },
        })

        const userContext = { ..._user, ...userCreated }
        console.log("userContext ", userContext)
        setUser(userContext)
        setIsAuthenticated(true)
        setIsLoading(false)
        setScopesGranted(userCreated.scopes)
        setAccessToken(_user.accessToken)
      } else {
        setUser(null)
        setIsAuthenticated(false)
        setIsLoading(false)
        setScopesGranted([])
        setAccessToken(null)
      }
    })

    return () => unsubscribe()
  }, [])

  const value = useMemo(
    () => ({
      user,
      isAuthenticated,
      isLoading,
      scopesGranted,
      logout: () => {
        signOut(auth)
          .then(() => navigate("/"))
          .catch(e => console.log("signOut error", e))
      },
      getIsAuthorized: requiredScopes =>
        isAuthenticated &&
        requiredScopes.some(a => scopesGranted.some(m => a === m)),
      accessToken,
      updateUserState: info =>
        setUser({ ...user, completedProfile: true, ...info }),
    }),
    [user, isAuthenticated, isLoading, scopesGranted, accessToken]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
